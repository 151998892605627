import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";

import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import "../styles/home.scss";
import Layout from "../components/layout";
import NewsletterModal from "../components/newsletterModal";

const HomePage = ({ data }) => {
  const carouselItems = data.sanityHome.carouselItems;

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(sessionStorage.getItem("modalDismissed") === null);
  }, []);

  const closeModal = () => {
    setModalOpen(false);
    sessionStorage.setItem("modalDismissed", true);
  };

  const ItemLink = ({ linkedCollection, children }) => {
    if (linkedCollection !== null) {
      return (
        <Link to={"/shop/" + linkedCollection.slug.current}>{children}</Link>
      );
    } else {
      return <>{children}</>;
    }
  };

  return (
    <Layout mainClass="home">
      <NewsletterModal modalOpen={modalOpen} closeModal={closeModal} />
      <div className="hero-image">
        <div className="slide-container">
          <Fade arrows={false} pauseOnHover={false}>
            {carouselItems.map((item, index) => (
              <div className="slide each-fade" key={index}>
                <ItemLink linkedCollection={item.linkedCollection}>
                  <Img
                    tag="div"
                    className="slide-image"
                    fluid={item.image.asset.fluid}
                  />
                </ItemLink>
              </div>
            ))}
          </Fade>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    sanityHome(_id: { eq: "single-home" }) {
      carouselItems {
        image {
          asset {
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
        linkedCollection {
          slug {
            current
          }
        }
      }
    }
  }
`;
export default HomePage;
