import React, { useRef, useEffect, useCallback } from "react";
import MailchimpForm from "./mailchimpForm";

import "../styles/newsletter-modal.scss";

const NewsletterModal = ({ modalOpen, closeModal }) => {
  const modalElement = useRef(null);

  const escFunction = useCallback(
    event => {
      if (event.key === "Escape") {
        closeModal();
      }
    },
    [closeModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction);
    return () => {
      document.removeEventListener("keydown", escFunction);
    };
  }, [escFunction]);

  useEffect(() => {
    if (modalOpen) {
      modalElement.current.classList.add("is-active");
    } else {
      modalElement.current.classList.remove("is-active");
    }
  }, [modalOpen]);

  return (
    <div className="modal" ref={modalElement}>
      <div className="modal-background" onClick={closeModal} />
      <div className="modal-content">
        <div className="box">
          <h5>Join our newsletter to get VIP access to upcoming sales</h5>
          <MailchimpForm />
        </div>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={closeModal}
      ></button>
    </div>
  );
};

export default NewsletterModal;
